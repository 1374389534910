var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.authenticated)?_c('NotPermission'):_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('Breadcrumbs',{attrs:{"routes":[{ name: 'Inicio' }, { name: 'cupones' }, { name: 'Listado' }]}}),_c('v-card',[_c('v-toolbar',{attrs:{"color":"grey darken-1","dark":"","card":""}},[_c('v-toolbar-title',[_vm._v("Listado de Cupones")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'CreateCupon' },"color":"primary"}},[_c('svg',{staticStyle:{"width":"24px","height":"24px","margin-right":"10px"},attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z"}})]),_vm._v(" Agregar Cupón ")])],1),_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm6":""}}),_c('v-flex',{attrs:{"sm6":""}},[_c('v-text-field',{attrs:{"box":"","label":"Buscar","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
              { text: 'N°' },
              { text: 'Código', value: 'codigo' },
              { text: 'Tipo', value: 'tipo' },
              { text: 'Total', value: 'cantidad_inicial' },
              { text: 'Canjeados' },
              { text: 'Restantes' },
              { text: 'Fecha Inicio - Término', value: 'fecha_inicio' },
              { text: 'Estado', value: 'estado' },
              { text: 'Acciones' }
            ],"items":_vm.cupones,"search":_vm.search,"loading":_vm.loadingCupones,"rows-per-page-items":[10, 25, 35, 50]},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('tr',{},[_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.id)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.codigo)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.tipo)+" ")]),_c('td',{staticClass:"px-3",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(props.item.cantidad_inicial)+" ")]),_c('td',{staticClass:"px-3",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(props.item.cantidad_inicial - props.item.cantidad_disponible)+" ")]),_c('td',{staticClass:"px-3",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(props.item.cantidad_disponible)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.fecha_inicio))+" - "+_vm._s(_vm.formatDate(props.item.fecha_termino))+" ")]),_c('td',{staticClass:"px-3"},[(props.item.estado === true)?_c('v-chip',{attrs:{"small":"","color":"primary","text-color":"white"}},[_vm._v(" ACTIVO ")]):(props.item.estado === false)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" INACTIVO ")]):_vm._e()],1),_c('td',{staticClass:"text-xs-center px-3"},[_c('v-btn',{staticClass:"ma-0",attrs:{"to":{ name: 'EditCupon', params: { id: props.item.id } },"small":"","icon":"","flat":"","color":"info"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1),_c('v-btn',{staticClass:"ma-0",attrs:{"small":"","flat":"","icon":"","color":"error"},on:{"click":function($event){return _vm.openModalDeleteCupon(props.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)],1)])}}])})],1)],1)],1)],1),_c('ModalDelete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }