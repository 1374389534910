<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'cupones' }, { name: 'Listado' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Cupones</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CreateCupon' }" color="primary">
          <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Cupón
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex sm6> </v-flex>
          <v-flex sm6>
            <v-text-field v-model="search" box label="Buscar" clearable hide-details />
          </v-flex>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'N°' },
                { text: 'Código', value: 'codigo' },
                { text: 'Tipo', value: 'tipo' },
                { text: 'Total', value: 'cantidad_inicial' },
                { text: 'Canjeados' },
                { text: 'Restantes' },
                { text: 'Fecha Inicio - Término', value: 'fecha_inicio' },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones' }
              ]"
              :items="cupones"
              :search="search"
              :loading="loadingCupones"
              :rows-per-page-items="[10, 25, 35, 50]"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.id }}
                </td>
                <td class="px-3">
                  {{ props.item.codigo }}
                </td>
                <td class="px-3">
                  {{ props.item.tipo }}
                </td>
                <td class="px-3" style="text-align:center;">
                  {{ props.item.cantidad_inicial }}
                </td>
                <td class="px-3" style="text-align:center;">
                  {{ props.item.cantidad_inicial - props.item.cantidad_disponible }}
                </td>
                <td class="px-3" style="text-align:center;">
                  {{ props.item.cantidad_disponible }}
                </td>
                <td class="px-3">
                  {{ formatDate(props.item.fecha_inicio) }} -
                  {{ formatDate(props.item.fecha_termino) }}
                </td>
                <td class="px-3">
                  <v-chip
                    v-if="props.item.estado === true"
                    small
                    color="primary"
                    text-color="white"
                  >
                    ACTIVO
                  </v-chip>
                  <v-chip v-else-if="props.item.estado === false" small>
                    INACTIVO
                  </v-chip>
                </td>
                <td class="text-xs-center px-3">
                  <v-btn
                    class="ma-0"
                    :to="{ name: 'EditCupon', params: { id: props.item.id } }"
                    small
                    icon
                    flat
                    color="info"
                  >
                    <v-icon small>
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-0"
                    small
                    @click="openModalDeleteCupon(props.item)"
                    flat
                    icon
                    color="error"
                  >
                    <v-icon small>
                      delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <ModalDelete />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de cupones" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/cupones/ModalDelete")
  },

  data() {
    return {
      search: ""
    };
  },

  computed: {
    ...mapState({
      cupones: state => state.cupones.cupones,
      loadingCupones: state => state.cupones.loadingCupones
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getCupones();
  },

  methods: {
    ...mapActions({
      getCupones: "cupones/getCupones",
      replaceShowModalDeleteCupon: "cupones/replaceShowModalDeleteCupon",
      replaceCurrentCupon: "cupones/replaceCurrentCupon"
    }),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openModalDeleteCupon(cupon) {
      this.replaceCurrentCupon({ cupon });
      this.replaceShowModalDeleteCupon({ status: true });
    }
  }
};
</script>
